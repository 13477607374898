<template>
  <SubPageWrapper
    data-shortcut-table
    width="full"
  >
    <template #breadcrumbs>
      <SlBreadcrumb @click="handleBack">
        {{ $t(sourceData.locale) }}
      </SlBreadcrumb>
      <SlBreadcrumb
        :index="1"
        active
      >
        {{ $t('Web.ConstrainedPlan.PageTitle') }}
      </SlBreadcrumb>
    </template>
    <template #filter>
      <SlBadge
        v-if="activeFilterId !== DEFAULT_FILTER_ID"
        :close-tooltip="getTooltip($t('Web.Filters.RemoveFilter'))"
        closable
        @close="handleCloseFilter"
      >
        <template #text>
          <span class="subpage__filter-name">
            {{ $t('Web.Filters.FilteredByNew') }}
          </span>
          <span>
            {{ activeFilterName }}
          </span>
        </template>
      </SlBadge>
    </template>
    <ContentBlock>
      <div class="subpage__actions">
        <div class="subpage__actions-group">
          <SlSearchInput
            :value="search"
            class="w-280"
            @submit="handleSearch"
          />
          <SlControl
            :value="tableMetaInfo.type"
            :options="planOptions"
            :disabled="!tableMetaInfo.type"
            @input="setPlanType"
          />
          <template v-if="tableMetaInfo.sumBy">
            {{ $t('Analytics.Controls.TimeView') }}
            <SlSelect
              :value="tableMetaInfo.sumBy.val"
              :options="tableMetaInfo.sumBy.vals"
              track-by="val"
              label="label"
              class="w-150"
              @input="setSumBy"
            />
          </template>
        </div>
        <div class="subpage__actions-group">
          <SlBadge
            v-if="isCalculationRunning"
            v-tooltip.bottom="calculationProgressTooltip"
            :closable="hasPermissionToRun"
            @close="handleStopCalculation"
          >
            <template #text>
              <span>
                {{ $t('Web.ConstrainedPlan.CalcProgress', { 1: calculationProgress || 0 }) }}
              </span>
            </template>
          </SlBadge>
          <SlButton
            v-if="hasPermissionToRun"
            v-tooltip.bottom="lastRunDurationTooltip"
            variant="secondary"
            color="grey"
            @click="handleRunCalculation"
          >
            <template #prepend>
              <transition name="fade">
                <icon
                  v-if="!isCalculationRunning"
                  data="@icons/run.svg"
                  class="fill-off size-16"
                />
                <icon
                  v-else
                  data="@icons/re-run.svg"
                  class="fill-off size-16"
                />
              </transition>
            </template>
            {{ runButtonText }}
          </SlButton>
          <SlDropdown
            :target-label="$t('Main.Ui.acChooseExportToXlsx')"
            placement="bottom-end"
            :disabled="!tableMetaInfo.type"
          >
            <template #content>
              <SlDropdownOption
                v-for="option in exportOptions"
                :key="option.value"
                select-close
                @select="handleExportToXlsx(option.value)"
              >
                {{ option.label }}
              </SlDropdownOption>
            </template>
          </SlDropdown>
        </div>
      </div>
      <div class="subpage__table-wrapper">
        <div
          v-if="lastUpdatedLabel"
          class="meta-info"
        >
          <div class="meta-info__item">
            <span class="meta-info__label">
              {{ lastUpdatedLabel }}
            </span>
          </div>
        </div>
        <div class="subpage__table">
          <SlTable
            v-if="table"
            id="constrained-plan"
            :key="tableHeaders.length"
            :headers="tableHeaders"
            :rows="tableRows"
            unique-key="id"
            header-unique-key="key"
            header-name-key="name"
            header-type-key="type"
            :max-height="1000"
            :value-parser="parseValue"
            :api-sortable="canSort"
            :sort-by="colClass"
            :sort-order="sortOrder"
            :total-rows-count="totalRowsCount"
            :current-page="currentPage"
            :per-page="perPage"
            :loading="loading"
            :inner-no-data="!!search"
            pagination
            num-col
            highlight-row
            @page-change="handlePaginationChange"
            @per-page-change="handlePerPageChange"
            @sort="handleSort"
            @contextmenu:clipboard="(cb) => cb()"
          >
            <template #loader>
              <SlOverlay :show="loading" />
            </template>

            <template #no-data>
              <SlNoData>
                <template #image>
                  <icon
                    :data="noDataMeta.icon"
                    :class="{
                      'illustration-md': !search,
                      'size-48': search,
                      'fill-off': search
                    }"
                  />
                </template>
                <template #title>
                  {{ noDataMeta.title }}
                </template>
                <template #text>
                  {{ noDataMeta.text }}
                </template>
              </SlNoData>
            </template>
          </SlTable>
        </div>
      </div>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { modal } from '@/mixins/modal';
import { fileSaver } from '@/mixins/webAPI';
import { tableConfig } from '@/mixins/tableConfig';
import { access } from '@/mixins/access';
import { shortcut } from '@/mixins/shortcut';
import modalIds from '@/config/shared/modalsId.config';
import { namespaceByRoute } from '@/config/report';
import { exportOptions, planOptions, sourceByRoute } from '@/config/report/inventoryReport/constrainedPlan.config';
import { DEFAULT_FILTER_ID } from '@/config/filter';
import { getTooltip } from '@/helpers/shared/tooltip';
import { formatFullTimeAMPM, formatHoursMinutes } from '@/helpers/date/date';

export default {
  name: 'ConstrainedPlan',
  mixins: [modal, fileSaver, tableConfig, access, shortcut],
  data() {
    return {
      DEFAULT_FILTER_ID,
      modalIds,
      loading: false,
      calculationProgress: null,
      exportOptions: exportOptions(this),
      planOptions: planOptions(this)
    };
  },
  computed: {
    ...mapState({
      table: (state) => state.inventoryReport.constrainedPlan.table,
      tableConfig: (state) => state.inventoryReport.constrainedPlan.table_config,
      lastUpdated: (state) => state.inventoryReport.constrainedPlan.lastUpdated,
      lastRunDuration: (state) => state.inventoryReport.constrainedPlan.lastRunDuration,
      runningOperationStartStamp: (state) => state.inventoryReport.constrainedPlan.runningOperationStartStamp,
      runningOperationId: (state) => state.inventoryReport.constrainedPlan.runningOperationId
    }),
    ...mapGetters({
      commonParams: 'inventoryReport/constrainedPlan/commonParams'
    }),
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    sourceData() {
      return sourceByRoute[this.$sl_routeName];
    },
    activeFilterId() {
      return this.$store.state[this.reportNamespace]?.active_filter_id;
    },
    activeFilter() {
      return this.$store.getters[`${this.reportNamespace}/activeFilter`];
    },
    activeFilterName() {
      return this.activeFilter?.name || '';
    },
    // table
    tableMetaInfo() {
      return this.table?.metadata || {};
    },
    canSort() {
      return this.tableMetaInfo.canSort;
    },
    tableHeaders() {
      return this.table?.headers || [];
    },
    tableRows() {
      return this.table?.rows || [];
    },
    totalRowsCount() {
      return this.table?.totalRowsCount;
    },
    currentPage() {
      return this.table?.currentPage;
    },
    lastUpdatedLabel() {
      if (!this.lastUpdated) {
        return null;
      }

      const formattedDate = formatFullTimeAMPM(this.lastUpdated);

      return this.$t('Web.ConstrainedPlan.LastUpdated', {
        1: formattedDate
      });
    },
    lastRunDurationTooltip() {
      if (!this.lastRunDuration) {
        return null;
      }

      const lastRunDuration = formatHoursMinutes(this.lastRunDuration);

      return getTooltip(
        this.$t('Web.ConstrainedPlan.RunTook', { 1: lastRunDuration })
      );
    },
    calculationProgressTooltip() {
      if (!this.runningOperationStartStamp) {
        return;
      }

      const formattedDate = formatFullTimeAMPM(this.runningOperationStartStamp);

      return getTooltip(
        this.$t('Web.ConstrainedPlan.StartedAt', { 1: formattedDate })
      );
    },
    isCalculationRunning() {
      return Boolean(this.runningOperationId);
    },
    runButtonText() {
      if (this.isCalculationRunning) {
        return this.$t('Web.ConstrainedPlan.RerunCalc');
      }

      return this.$t('Web.ConstrainedPlan.RunCalc');
    },
    noDataMeta() {
      if (this.search) {
        return {
          icon: require('@icons/style_no_search_results_double.svg'),
          title: this.$t('Web.NoData.SearchTitle'),
          text: this.$t('Web.NoData.SearchText')
        };
      }

      return {
        icon: require('@icons/illustration/no-table-rows.svg'),
        title: this.$t('Web.ConstrainedPlan.NoDataTitle'),
        text: this.hasPermissionToRun ? this.$t('Web.ConstrainedPlan.NoDataText') : ''
      };
    },
    hasPermissionToRun() {
      return this.$sl_hasAccess(this.$sl_features.updateConstrainedOrderingPlan);
    }
  },
  watch: {
    isCalculationRunning(isRunning) {
      if (isRunning && this.calculationProgress === null) {
        this.waitOperation({
          id: this.runningOperationId,
          callbacks: {
            in_progress: this.setProgress,
            finished: () => {
              this.calculationProgress = null;
            }
          }
        });

        this.calculationProgress = 0;
      }
    }
  },
  async beforeMount() {
    await this.fetchConstrainedPage();
  },
  destroyed() {
    this.resetState();
  },
  methods: {
    ...mapActions({
      fetchConstrainedPlan: 'inventoryReport/constrainedPlan/fetchConstrainedPlan',
      runCalculation: 'inventoryReport/constrainedPlan/runCalculation',
      resetState: 'inventoryReport/constrainedPlan/resetState',
      exportToXlsx: 'inventoryReport/constrainedPlan/exportToXlsx',
      updateTableMetadata: 'inventoryReport/constrainedPlan/updateTableMetadata',
      updateTableConfig: 'inventoryReport/constrainedPlan/updateTableConfig',
      subscribe: 'operations/subscribe',
      cancel: 'operations/cancel',
      waitOperation: 'operations/waitOperation'
    }),
    getTooltip,
    async fetchConstrainedPage(isUpdate = false) {
      try {
        if (!isUpdate) {
          this.loading = true;
        }

        await this.fetchConstrainedPlan(true);
      } finally {
        this.loading = false;
      }
    },
    handleBack() {
      this.$router.push({
        name: this.sourceData.route
      });
    },
    async handleRunCalculation() {
      if (!this.isCalculationRunning) {
        this.subscribe({
          subscriber: this.runCalculation,
          in_progress: this.setProgress,
          finished: () => {
            this.calculationProgress = null;
          }
        });

        this.calculationProgress = 0;

        return;
      }

      this.showModal(modalIds.SL_CONFIRM_MODAL, {
        title: this.$t('Web.ConstrainedPlan.RerunTitle'),
        text: this.$t('Web.ConstrainedPlan.RerunText'),
        icon: 'style_warning_double',
        confirmText: this.$t('Web.ConstrainedPlan.RerunBtn'),
        confirmCallback: async() => {
          await this.stopCalculation();
          this.handleRunCalculation();
        }
      });
    },
    handleStopCalculation() {
      this.showModal(modalIds.SL_CONFIRM_MODAL, {
        title: this.$t('Web.ConstrainedPlan.StopTitle'),
        text: this.$t('Web.ConstrainedPlan.StopText'),
        icon: 'style_warning_double',
        confirmText: this.$t('Web.ConstrainedPlan.StopBtn'),
        confirmCallback: this.stopCalculation
      });
    },
    async stopCalculation() {
      if (!this.isCalculationRunning) {
        return;
      }

      await this.cancel({ id: this.runningOperationId });
    },
    setProgress({ progress }) {
      this.calculationProgress = progress.current;
    },
    // filters
    async handleCloseFilter() {
      await this.$store.dispatch(`${this.reportNamespace}/setActiveFilterId`, DEFAULT_FILTER_ID);

      this.fetchConstrainedPage();
    },
    parseValue(cellValue) {
      if (!cellValue) {
        return '';
      }

      if (typeof cellValue !== 'object') {
        return cellValue;
      }

      return cellValue.val || cellValue.auto || '';
    },
    // search
    handleSearch(query) {
      if (!this.search && !this.totalRowsCount) {
        return;
      }

      this.search = query;

      this.fetchConstrainedPage();
    },
    // export
    handleExportToXlsx(type) {
      this.saveFile(this.exportToXlsx.bind(this, { type }));
    },
    // table configuration
    handlePaginationChange(page) {
      this.page = page;

      this.fetchConstrainedPage();
    },
    handlePerPageChange(pageSize) {
      this.perPage = pageSize;
      this.page = 1;

      this.fetchConstrainedPage();
    },
    async handleSort({ callback }) {
      const { key, order } = callback();

      this.sortOrder = order;
      this.colClass = key;

      try {
        this.loading = true;

        await this.setSortingParams({
          config: this.tableConfig,
          params: {
            table: 'constrained_plan',
            ...this.commonParams
          }
        });
      } finally {
        this.loading = false;
      }
    },
    async setPlanType(type) {
      this.page = 1;
      await this.updateTableMetadata({ key: 'type', value: type });

      this.fetchConstrainedPage();
    },
    async setSumBy(sumBy) {
      await this.updateTableMetadata({ key: 'sumBy', value: { val: sumBy } });

      this.fetchConstrainedPage();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_color.scss";
@import "@/style/utils/_variable.scss";
@import "@/style/utils/_mixins.scss";

.content-block {
  .meta-info {
    display: flex;
    align-items: center;
    column-gap: 20px;
    width: fit-content;
    margin-bottom: 12px;
    padding: 5px 12px;

    background: $grey-10;
    border-radius: $border-radius-sm;

    .meta-info__item {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    .meta-info__label {
      color: $grey-80;
      @include body-1;
    }
  }

  .subpage__table-wrapper {
    height: calc(100% - 92px);
  }
}
</style>
